import "../years/years.css";
import React from "react";

const Years = (props) => {
  return (
    <>
      <div className="mains">{props.number}</div>
      <div className="atr">
        <span style={{color : "#699BF7", fontSize : "25px"}}>+</span>
        <span>{props.attri}</span>
      </div>
    </>
  );
};

export default Years;
