import React from "react";
import "../FaqContent/faqcon.css"

const Faqcontent = (props) => {
  return (
    <>
      <div className="accordion accordion-flush" id="accordionExample" data-bs-theme="dark">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${props.ids}`}
              aria-expanded="false"
              aria-controls={props.ids}
            >
              {props.topic}
            </button>
          </h2>
          <div
            id={props.ids}
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">{props.content}</div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default Faqcontent;
