import React from "react";
import "./card.css";
import bullet from "../../../../assets/Services/Vector.png"

const Cards = (props) => {
  return (
    <>
      <div
        className="card cards card-2 text-center"
        // style={{ width: "18rem;" }}
      >
        <div className="card-body">
          <h5 className="card-title" >
            {props.head}
          </h5>
          <div className="list">
            <div className="listitem">
              {/* <span className="bullicon">
              <img style={{height : "14px"}} src={bullet} alt="" srcset="" />
              </span> */}
              <span> |&nbsp; &nbsp;{props.l1}</span>
            </div>
            <div className="listitem">
              {/* <span className="bullicon">
              <img style={{height : "14px"}} src={bullet} alt="" srcset="" />
              </span> */}
              <span>|&nbsp; &nbsp;{props.l2}</span>
            </div>
            <div className="listitem">
              {/* <span className="bullicon">
              <img style={{height : "14px"}} src={bullet} alt="" srcset="" />
              </span> */}
              <span>|&nbsp; &nbsp;{props.l3}</span>
            </div>
            <div className="listitem">
              {/* <span className="bullicon">
              <img style={{height : "14px"}} src={bullet} alt="" srcset="" />
              </span> */}
              <span>|&nbsp; &nbsp;{props.l4}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
