import React from "react";
import style from "./creative.module.css";
import Cards from "../Creative/card/Cards";

const details = [
  {
    head: "Strategy",
    l1: "Front-end developer",
    l2: "Backend Developer",
    l3: "Full stack Developer",
    l4: "QA",
  },
  {
    head: "Design",
    l1: "Front-end developer",
    l2: "Backend Developer",
    l3: "Full stack Developer",
    l4: "QA",
  },
  {
    head: "Development",
    l1: "Front-end developer",
    l2: "Backend Developer",
    l3: "Full stack Developer",
    l4: "QA",
  },
  {
    head: "Content",
    l1: "Front-end developer",
    l2: "Backend Developer",
    l3: "Full stack Developer",
    l4: "QA",
  },
];

const Creative = () => {
  return (
    <>
      <div className={style.blackBox1}>
        <h1>Creative Quotient</h1>
        <div className={style.content}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quos!
        </div>
      </div>

      <div className={style.backimg}>
        <div className={style.cardSection}>
          {details.map((elements, key) => (
            <Cards
              key={key}
              head={elements.head}
              l1={elements.l1}
              l2={elements.l2}
              l3={elements.l3}
              l4={elements.l4}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Creative;
