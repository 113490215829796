import React from "react";
import "../Blackstrip/blackstrip.css";
const Blackstrip = () => {
  const names = [
    "Web Development",
    "UI/UX",
    "Mobile Development",
    "QA",
    "DevOps",


  ];
  const updatedlist = names.map((element) => {
    return (
      <>
        <div className="discorange"></div>
        <li>{element}</li>
      </>
    );
  });
  return (
    <>
      <div className="listing">
        <marquee>

          <ul>
            {updatedlist}
          </ul>

        </marquee>
      </div>
    </>
  );
};

export default Blackstrip;
