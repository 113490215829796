import React from "react";
import style from "../invite/invite.module.css";
import Vector from "../../../../assets/career/Vector.png";
import dp from "../../../../assets/career/dp.png";

const Invite = () => {
  return (
    <>
      <div className={style.invitecard}>
        <div className={style.comma}>
          <img src={Vector} />
        </div>
        <div className={style.inform}>
          <div className={style.dp}>
            <img src={dp} />
          </div>
          <p className={style.content}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
          <div className={style.intro}>- Some Client, Works as CEO</div>
        </div>
      </div>
    </>
  );
};

export default Invite;
