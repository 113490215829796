import React from "react";
// import "../Whywe/whywe.css";
import img1 from "../../../assets/home/Rectangle 39732.png";
import img from "../../../assets/home/Screenshot 2024-03-01 at 11.48 1.png";
import "../boxwhite/boxwhite.css"
import style from "../Whywe/whywe.module.css"

const Whywe = () => {
  return (
    <>
      <div className={style.first3}>
        <div className={style.imagess3}>
          <img src={img1} alt="React Image" />
          <img className={style.img1} src={img} alt="React Image" />
        </div>
        <div className={style.left23}>
          <h3 style={{fontSize : "20px"}}>Why Choose Us?</h3>
          <h1>Chasing a Big Dream? <br /> We get it! </h1>
          <p style={{ marginTop: "30px"}}>
            Those all-nighters, the feeling that you're this close to building
            something awesome...and the frustration of tech stuff getting in the
            way. That's where Cubexo comes in. Think of us like your dream
            in-house tech team – the ones who get your crazy deadlines, speak
            your language, and honestly just want to see your idea take off. No
            more hunting for freelancers or trying to figure out code on your
            own. We'll align with your vision, work at your speed, and be there
            to tackle those startup tech headaches so you can get back to what
            you do best – building your business.
          </p>
          <div className={style.line}></div>
        </div>
        
      </div>
    </>
  );
};

export default Whywe;
