import React from "react";
import style from "../social-icons/headersocial.module.css";
import insta from "../../../assets/home/social-icons/instagram.png";
import drib from "../../../assets/home/social-icons/Dribble.png";
import twit from "../../../assets/home/social-icons/Twitter.png";
import linkd from "../../../assets/home/social-icons/Linkdin.png";

const HeaderSocials = (props) => {


  return (
    <div className={style[props.clas1]}>
    
      <a href="/" target="_blank">
        <img src={insta} alt="icon" />
      </a>
      <a href="/" target="_blank">
        <img src={drib} alt="icon" />
      </a>
      <a href="/" target="_blank">
        <img src={linkd} alt="icon" />
      </a>
      <a href="/" target="_blank">
        <img src={twit} alt="icon" />
      </a>
    </div>
  );
};
export default HeaderSocials;
