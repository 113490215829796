import React from "react";
import style from "../Blogs/blogs.module.css";
import BlogCards from "../../components/Blogs/BlogCards/BlogCards";
import img from "../../assets/blogs/Sample.png";
import img2 from "../../assets/blogs/sample2.png";
import img3 from "../../assets/blogs/sample3.png";
import Navbar from "../../components/Shared/navbar/Navbars";

const Blogs = () => {
  return (
    <>
      <Navbar blogs="uline" />
      <div className="">
        <div className={style.blogRows}>
          <div className={style.cardSize}>
            <BlogCards img={img} />
          </div>
          <div className={style.cardSize}>
            <BlogCards img={img} />
          </div>
          <div className={style.cardSize}>
            <BlogCards img={img} />
          </div>
        </div>
        <div className={style.blogRows}>
          <div className={style.cardSize}>
            <BlogCards img={img2} />
          </div>
          <div className={style.cardSize}>
            <BlogCards img={img2} />
          </div>
          <div className={style.cardSize}>
            <BlogCards img={img2} />
          </div>
        </div>
        <div className={style.blogRows}>
          <div className={style.cardSize}>
            <BlogCards img={img3} />
          </div>
          <div className={style.cardSize}>
            <BlogCards img={img3} />
          </div>
          <div className={style.cardSize}>
            <BlogCards img={img3} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
