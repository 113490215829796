import React, { useState, useRef } from "react";
import style from "../Contact/contact.module.css";
import Darkside from "./darkside/Darkside";
import Navbar from "../../components/Shared/navbar/Navbars";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email_id: "",
    phn_no: "",
    message: "",
  });
  const handleKeyPress = (e) => {
    // Allow only numbers and backspace
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    // Check if the input is a number or backspace
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }

    // Check if the input length exceeds 12 characters
    if (e.target.value.length >= 14 && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData.firstName.trim() === "" ||
      formData.lastName.trim() === "" ||
      formData.email_id.trim() === "" ||
      formData.phn_no.trim() === "" ||
      formData.message.trim() === ""
    ) {
      alert("Empty block");
    } else {
      emailjs
        .sendForm("service_v7ci1c9", "template_6uh026o", form.current, {
          publicKey: "aP2pL-ImJj5tHbLHZ",
        })
        .then(
          () => {
            // console.log("SUCCESS!");

            setFormData({
              firstName: "",
              lastName: "",
              email_id: "",
              phn_no: "",
              message: "",
            });
          },
          (error) => {
            console.log("FAILED...");
          }
        );
    }
  };

  return (
    <>
      <Navbar contact="uline" />
      <div className={style.contactcon}>
        <h1> Contact US </h1>
        <p>Any Question or remark ? just write a message</p>
        <div className={style.fom}>
          <Darkside />
          <div className={style.contac}>
            <form ref={form} onSubmit={handleSubmit}>
              <div className={style.name}>
                <div className={style.userBox}>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  <label>First Name</label>
                </div>
                <div className={style.userBox}>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  <label>Last Name</label>
                </div>
              </div>
              <div className={style.numb}>
                <div className={style.userBox}>
                  <input
                    type="email"
                    name="email_id"
                    value={formData.email_id}
                    onChange={handleChange}
                  />
                  <label>Email</label>
                </div>
                <div className={style.userBox}>
                  <input
                    type="tel"
                    name="phn_no"
                    value={formData.phn_no}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                  />
                  <label>Phone number</label>
                </div>
              </div>
              <div className={style.userBox}>
                <input
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your Message"
                />
                <label>Message</label>
              </div>
              <div className={style.button}>
                <button
                  type="submit"
                  value="Send"
                  className={`${style.btn} ${style.btnDark}`}
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
