import React from "react";
import { Link } from "react-router-dom";
import "../navbar/navbars.css";
import Logo from "../../../assets/Logo.png";

function Navbar(props) {
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark"
        style={{ backgroundColor: "#120f07" }}
      >
        <div className="container-fluid align logo">
          <Link
            style={{ padding: "32px 0 31.56px 39px" }}
            className="navbar-brand"
            to="/"
          >
            <img src={Logo} />
          </Link>
          <div className="floating">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="blueline navbar-nav me-auto mb-2 mb-lg-0 ">
                <li className="nav-item " data-animation="to-left">
                  <Link className={`nav-link blue ${props.home}`} to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={`nav-link blue ${props.about}`} to="/about">
                    About Us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className={`nav-link blue ${props.blogs}`} to="/blogs">       
                    Blogs
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className={`nav-link blue ${props.services}`} to="/services">
                    Services
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className={`nav-link blue ${props.career}`} to="/career">
                    Career
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className={`nav-link blue ${props.contact}`} to="/contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
