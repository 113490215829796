import React from "react";
import Devlop from "../../components/Services/Develop/Devlop";
import Faq from "../../components/Services/Faq/Faq";
import style from "../Services/services.module.css";
import Creative from "../../components/Services/Creative/Creative";
import Navbar from "../../components/Shared/navbar/Navbars";

const Services = () => {
  return (
    <>
      <Navbar services = "uline" />

      <div className={style.blackBox}>
        <h1>Services</h1>
        <div className={style.content}>
          Don't let technology hold you back. Cubexo's team of vetted developers
          seamlessly adapts to your preferred platforms and languages. Get the
          specialized talent you need to tackle ambitious projects, expand into
          new markets, or confidently pivot your digital strategy.
        </div>
      </div>
      <div className={style.yellowBox}>
        <p>
          Fast, flexible, and tailored to your needs. Get the tech support you
          deserve.{" "}
        </p>
      </div>
      <Devlop back="#FAFAFA" />
      {/*<Design back = "#FAFAFA" />*/}

      <Creative />
      <Faq />
      {/* <Getin /> */}
      {/* <Footer/> */}
    </>
  );
};

export default Services;
