import React from "react";
import style from "../darkside/darkside.module.css";
import phone from "../../../assets/contact/phone.png";
import locat from "../../../assets/contact/location.png";
import mail from "../../../assets/contact/mail.png";
import HeaderSocials from "../../../components/Home/social-icons/HeaderSocials";
import smallbox from "../../../assets/contact/smallbox.png";
import boxbig from "../../../assets/contact/bigbox.png";

const Darkside = () => {
  return (
    <>
      <div className={style.back}>
        <h3>Contact Information</h3>
        <p>Say something to start a live chat!</p>
        <div className={style.info}>
          <div className={style.phone}>
            <span>
              <img className={style.icons} src={phone} alt="phone" />
            </span>
            <span>+91-9111410001</span>
          </div>
          <div className={style.mail}>
            <span>
              <img className={style.icons} src={mail} alt="mail" />
            </span>
            <span>inbox@cubxo.io</span>
          </div>
          <div className={style.locat}>
            <span>
              <img className={style.icons} src={locat} alt="location" />
            </span>
            <span>
              2nd Floor, MR Business Park, Rani Bagh Main,
              <br /> Indore, Madhya Pradesh 452020
            </span>
          </div>
        </div>
        <div className={style.boxsmall}>
          <img src={smallbox} alt="" srcset="" />
        </div>
        <div className={style.foot}>
          {/* <HeaderSocials clas1="base_socials" /> */}
          <div className={style.bigbox}>
            <img src={boxbig} alt="" srcset="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Darkside;
