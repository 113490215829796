import React from "react";
import style from "../Positions/position.module.css";
import Faqcontent from "../../Shared/FaqContent/Faqcontent";

const Pos = [
  {
    topic: "Sr. Developer",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque cum ab voluptas at eius, asperiores libero sapiente sint provident nihil!",
    ids: "collapseOne",
  },
  {
    topic: "Sr. UX Designer",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque cum ab voluptas at eius, asperiores libero sapiente sint provident nihil!",
    ids: "collapseTwo",
  },
  {
    topic: "Front End Intern",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque cum ab voluptas at eius, asperiores libero sapiente sint provident nihil!",
    ids: "collapseThree",
  },
  {
    topic: "Sales and Marketing Executive",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque cum ab voluptas at eius, asperiores libero sapiente sint provident nihil!",
    ids: "collapseFour",
  },
  {
    topic: "DevOps Engineer",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque cum ab voluptas at eius, asperiores libero sapiente sint provident nihil!",
    ids: "collapseFive",
  },
];

const Positions = () => {
  return (
    <>
      <div className={style.out}>
        <div className={style.heade}>
          <h2>Current Open Positions</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique.
          </p>
        </div>
        <div className={style.accord}>
          {Pos.map((element, index) => (
            <Faqcontent
              key={index}
              topic={element.topic}
              content={element.content}
              ids={element.ids}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Positions;
