import React from "react";
import "../Getintouch/getin.css";
import mouse from "../../../assets/Services/mouse.png"
const Getin = () => {
  return (
    <>
      <div className="blackBox3">
        <h1>Tech & Design, Your Way. Let's Chat!</h1>
        <div className="line"></div>

        <div className="short">Get in Touch</div>
        <a href="/contact" className="mouse">
         <img src={mouse} alt="mouse"/>
        </a>
      </div>
    </>
  );
};

export default Getin;
