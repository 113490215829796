import React from "react";
import HeaderSocials from "../../components/Home/social-icons/HeaderSocials";

import style from "../Home/home.module.css";
import Blackstrip from "../../components/Home/Blackstrip/Blackstrip";
import Whitestrip from "../../components/Home/Whitestrip/Whitestrip";
import Aboutb from "../../components/Home/AboutB/Aboutb";
import Values from "../../components/Home/Values";
import Reviews from "../../components/Home/Reviews";
import Boxwhite from "../../components/Home/boxwhite/Boxwhite";
import Develop from "../../components/Services/Develop/Devlop";
import Empty from "../../components/Home/Empty/Empty";
import Whywe from "../../components/Home/Whywe/Whywe";
import Scroll from "../../components/Shared/scrolldown/Scroll";
import SideBar from "../../components/Shared/SideBar/SideBar";
import ClientCom from "../../components/Home/clientCompany/ClientCom";
import Navbar from "../../components/Shared/navbar/Navbars";

const Home = () => {
  return (
    <>
      <Navbar home="uline" />
      {/* <SideBar/> */}
      <div className={style.homies}>
        <div className={style.left}>
          <span className={style.line}>Build Profitable</span>
          <span className={style.line}>
            Digital <font> Products</font> with
          </span>
          <span className={style.line}>Top-Tier Global Talent</span>
          <Aboutb
            btn="CONTACT US"
            link1="/contact"
            btn2="PORTFOLIO"
            link2="/"
          />
        </div>
        {/* <HeaderSocials clas1 = "header_socials" /> */}{" "}
        {/* socialmedia icons  */}
        <Blackstrip />
        {/* <Scroll/> */}
      </div>
      <div className="container">
        <Boxwhite />
        {/* <Empty />   video section  */}
        <Whywe />
        <Values />
        <Develop back="#FAFAFA" />

        <ClientCom />
        {/* <Reviews /> */}
      </div>
    </>
  );
};

export default Home;
