import React from "react";
import styles from "./footer.module.css";
import Getin from "../../Services/Getintouch/Getin";
import log from "../../../assets/Logo.png";

function Footer() {
  const d = new Date();
  let year = d.getFullYear();
  const servicesData = [
    {
      header: "Development Services",
      data: [
        "DevOps & Development",
        "Web Development",
        "QA Testing",
        "Mobile Development",
        "Blockchain Development",
      ],
    },
    {
      header: "Design Services",
      data: ["UI/UX Design", "Graphic Design", "Brand Identity", "Animation"],
    },
    {
      header: "Marketing Services",
      data: ["SEO", "Social Media Marketing", "Content Marketing", "PPC"],
    },
  ];

  return (
    <div className={styles.container}>
      <Getin />
      <div className={styles.mainBox}>
        <div className={styles.upwardLogo}>
          <div className={styles.cubexo}> <img src={log} alt="" /></div>
          <div className={styles.logo}>
            <i class="fa-solid fa-arrow-up"></i>
          </div>
        </div>
        <div className={styles.Footer_right}>
          <div className={styles.servicesBox}>
            {servicesData.map((el, id) => {
              return (
                <div styles={styles.servicesColumn}>
                  <div key={id} className={styles.heading}>
                    {el.header}
                  </div>
                  {el.data.map((text) => {
                    return <div className={styles.desc}>{text}</div>;
                  })}
                </div>
              );
            })}
          </div>

          <div className={styles.contact}>
            <div className={styles.contact_desc}>
              <div className={styles.mediumFont}>
                We will be happy to help you
              </div>
              <div className={styles.mediumFont_light}>Email Address </div>
              <div class={styles.horizontal_line}></div>
            </div>
            <div className={styles.btnBox}>
              <button className={styles.contact_btn}>Call Me Back</button>
            </div>
          </div>
          <div className={styles.follow}>
            <div className={styles.follow_left}>
              {/* <div>Follow Us</div> */}
              <div className={styles.follow_logo_box}>
                <div className={styles.addr}>
                  <i className="fa-solid fa-location-dot"></i> 2nd Floor, MR
                  Business Park, Rani Bagh Main, Indore, Madhya Pradesh 452020
                </div>
                {/* <button>
                  <i class="fa fa-linkedin" aria-hidden="true"></i>
                </button>
                <button>
                  <i class="fa-brands fa-facebook-f"></i>
                </button>
                <button>
                  <i class="fa-brands fa-instagram"></i>
                </button>
                <button>
                  <i class="fa-brands fa-youtube"></i>
                </button> */}
                <div>
                <i className="fa fa-phone" aria-hidden="true"></i> +91-91114
                10001
              </div>
              </div>
              <div>@{year} - COPYRIGHT PRIVACY</div>
      
            </div>
            <div className={styles.follow_right}>
              
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
