import React from "react";
import style from "../BlogCards/Blogcard.module.css";
import img from "../../../assets/blogs/dps/Avatar.png";

const BlogCards = (props) => {
  return (
    <>
      <div className={style.CardOuter}>
        <img src={props.img} alt="" />
        <h2> This is card sample</h2>
        <p>
          {" "}
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus,
          recusandae.
        </p>
        <div className={style.user}>
          <div className={style.dp}>
            <img src={img} alt="..." />
          </div>
          <div className={style.userdetails}><span>Wade Warren</span> &nbsp; &nbsp; | &nbsp; &nbsp; <span>2nd January, 2022</span></div>
        </div>
      </div>
    </>
  );
};

export default BlogCards;
