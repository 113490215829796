import React from "react";
// import "../About/about.css";
import img from "../../assets/aboutframe1.png";
import img1 from "../../assets/aboutframe2.png";
import Boxwhite from "../../components/Home/boxwhite/Boxwhite";
import Cardss from "../../components/About/carding/Cardss";
import Footer from "../../components/Shared/footer";
import Navbar from "../../components/Shared/navbar/Navbars";
import style from "../About/about.module.css";
import Scroll from "../../components/Shared/scrolldown/Scroll";
import Galary from "../../components/Shared/PhotoGallary/Galary";

const About = () => {
  return (
    <>
      <Navbar about = "uline"/>
      <h1 className={style.resphead}>About Us</h1>
      <div className={style.first}>
        <div className={style.left2}>
          <h1>About Us</h1>
          <p>
            CubexO was born in 2019 – honestly, it started with a whiteboard
            scribbled so messy you couldn't read it, and a late-night idea that
            wouldn't let us sleep. We wanted to help businesses build digital
            products that truly shine... products people get excited about,
            products that fuel your whole mission. We'd seen enough clunky
            interfaces, wasted budgets... we were determined to change that.
            <br />
            <br />
            So, CubexO? It's a global network of brilliant devs and designers,
            yeah, and yeah, we've got smart matching (took some late-nights to
            build that too!). But really, it's about those user 'aha!'
            moments—wisdom interwoven with innovation, where every line of code
            whispers insights, and every design choice echoes a thoughtful
            strategy. Seeing your product not just function but truly make a
            meaningful difference is what sets CubexO apart.
            <br />
            <br /> We're the team that not only brings expertise to the table
            but also infuses a touch of wisdom into every project. We get as
            hyped about your launch as you do because, to us, it's not just
            about code and design; it's about crafting an experience that
            resonates and stands the test of time.
          </p>
        </div>
        <div className={style.imagess}>
          <img src={img1} alt="React Image" />
          <img className={style.img1} src={img} alt="React Image" />
        </div>
      </div>

      <Boxwhite />
      <Galary />
      {/* <Cardss />  */}
    </>
  );
};

export default About;
