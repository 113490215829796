import React from "react";
import "../AboutB/aboutb.css";
const Aboutb = (props) => {
  return (
    <>
      <div className="most">
        <div className="outer">
          <a className="text hover-underline-animation" href={props.link1}>
            {/* CONTACT US */}
            {props.btn}
          </a>
        </div>
        <div className="outer">
          <a className="text hover-underline-animation" href={props.link2}>
            {/* PORTFOLIO */}
            {props.btn2}
          </a>
        </div>
      </div>
    </>
  );
};

export default Aboutb;
