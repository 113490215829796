import React from "react";
import style from "../develop.module.css";

const Devcards = (props) => {
  return (
    <>
      <div className={style.develop__option}>
        <span className={style.spanicon}>
          <img src={props.imgs} alt="icon" />
        </span>
        <h5>{props.head}</h5>
        <p>{props.about}</p>
      </div>
    </>
  );
};

export default Devcards;
