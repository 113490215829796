import React from "react";
import styles from "./values.module.css";

function Values() {
  const valuesData = [
    {
      text: "Cubexo isn't just about slick designs or fancy code – we're about building digital experiences that transform your business. Whether you're an established player looking to shake things up or a scrappy startup ready to hit the big leagues, we've got the brains and the hustle to make it happen.",
    },
    {
      text: "We know your business is unique, and your technology should be too. That's why we ditch the templates and dive deep, understanding what gets your heart racing. From there, we craft custom-fit solutions – websites, apps, software – that turn your vision into a tangible, revenue-generating reality.",
    },
    {
      text: "At Cubexo, we're not just tech nerds (although, we kinda are). We're strategists, designers, and engineers who truly care about seeing you succeed. Think of us as an extension of your team, always ready to bounce ideas, troubleshoot challenges, and celebrate those big wins along the way.",
    },
  ];
  return (
    <div className={styles.container}>
      {valuesData.map((el, index) => {
        // Format the index number with leading zeros
        const formattedIndex = (index + 1).toString().padStart(2, "0");
        return (
          <div className={styles.valuesBox} key={index}>
            <div className={styles.valuesHeader}>{formattedIndex}</div>
            <div className={styles.valuesText}>{el.text}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Values;
