import React from "react";
import "../Faq/faq.css";
import Faqcontent from "../../Shared/FaqContent/Faqcontent";

const accordian = [
  {
    topic: "How do you handle project timelines and budgets?",
    content:
      "We establish upfront realistic timelines and budgets through clear communication and transparent processes. Regular progress updates and flexibility allow us to adapt as needed to keep projects on track.",
    ids: "collapseOne",
  },

  {
    topic: "Can you work with our existing tech stack?",
    content:
      "Absolutely! Our team is well-versed in a variety of technologie  and integration strategies. We'll assess your current setup and find the best ways to enhance or expand upon it.",
    ids: "collapseTwo",
  },

  {
    topic: "What are your processes for quality assurance and testing?",
    content:
      "Quality is non-negotiable. We follow rigorous testing protocols throughout development, encompassing unit testing, integration testing, and user acceptance testing. This proactive approach minimizes bugs and ensures a polished final product.",
    ids: "collapseThree",
  },

  {
    topic: "Do you offer ongoing support after a project is launched?",
    content:
      "Yes, we offer customizable maintenance and support packages. These can include bug fixes, updates, security monitoring, and optimization, ensuring your solutions continue to evolve with your business.",
    ids: "collapseFour",
  },

  {
    topic: "Can you share some examples of past projects?",
    content:
      "We'd be happy to! Depending on confidentiality agreements, we can provide case studies, general project descriptions, or high-level overviews that showcase our expertise within relevant industries or technologies.",
    ids: "collapseFive",
  },
];

const Faq = () => {
  

  return (
    <>
      <div className="container sections">
        <div style={{ textAlign: "center" }}>
          <h1 style={{ color: "#FAFAFA" }}>FAQs</h1>
        </div>
        {accordian.map((element, index)=>(
          <Faqcontent key={index} topic = {element.topic}  content ={element.content} ids = {element.ids}/>
        ))}
      </div>
    </>
  );
};

export default Faq;
