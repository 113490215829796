import React from "react";
import { useState } from "react";
import styles from "./reviews.module.css";
import client_image1 from "../../../assets/client1.png";
import client_image2 from "../../../assets/client2.png";

function Reviews() {
  const [index, setIndex] = useState(0);

  const Description1 =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde error incidunt alias. Consectetur cum, praesentium id quod veniam tempore minus beatae vitae dignissimos ipsa obcaecati. Beatae minima dolor nisi totam?";
  const Description2 =
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde error incidunt alias. Consectetur cum, praesentium id quod veniam tempore minus beatae vitae dignissimos ipsa obcaecati. Beatae minima dolor nisi totam?";
  const reviewData = [
    {
      pic: client_image1,
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id facilis consectetur assumenda maiores ratione dignissimos ipsam suscipit omnis cupiditate, similique perferendis aperiam magni. Ex, facilis explicabo praesentium veniam assumenda quis?",
      name: "Laurence Gogoi",
      designation: "CTO Linework",
    },
    {
      pic: client_image2,
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id facilis consectetur assumenda maiores ratione dignissimos ipsam suscipit omnis cupiditate, similique perferendis aperiam magni. Ex, facilis explicabo praesentium veniam assumenda quis?",
      name: "Nrupul Dev",
      designation: "CTO mindworks",
    },
    {
      pic: client_image2,
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id facilis consectetur assumenda maiores ratione dignissimos ipsam suscipit omnis cupiditate, similique perferendis aperiam magni. Ex, facilis explicabo praesentium veniam assumenda quis?",
      name: "Nrupul Dev",
      designation: "CTO mindworks",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>Our clients speak</div>
      <div className={styles.description_main}>{Description1}</div>
      <div className={styles.clientBox}>
        <div className={styles.imageBox}>
          <img
            className={styles.profile_pic}
            src={reviewData[index].pic}
            alt="client1"
          />
        </div>
        <div className={styles.desc}>
          <div>{reviewData[index].desc}</div>
          <div className={styles.profileDesc}>
            <div>{reviewData[index].name}</div>
            <div>{reviewData[index].designation}</div>
          </div>

          <div className={styles.paggination}>
            <button
              onClick={() => setIndex(index - 1)}
              disabled={index == 0}
              className={styles.btn}
            >
              Prev
            </button>
            <button
              onClick={() => setIndex(index + 1)}
              disabled={index == reviewData.length - 1}
              className={styles.btn}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className= {styles.pageDisplay}>
        {
            reviewData.map((el ,id)=>{
                return (
                    <button style={{background: id==index? "white" : "none"}} className= {styles.pageDisplayBtn}></button>
                )
            })
        }
      </div>
    </div>
  );
}

export default Reviews;
