import React from "react";
import style from "./develop.module.css";
import UI from "../../../assets/icons/UI.png";
import Mob from "../../../assets/icons/Mob.png";
import dev from "../../../assets/icons/Devlop.png";
import QA from "../../../assets/icons/QA.png";
import block from "../../../assets/icons/Block.png";
import cloud from "../../../assets/icons/cloud.png";
import Devcards from "./devCards/Devcards";

const cardinfo = [
  {
    logo: cloud ,
    heading: "DevOps & Cloud",
    about:
      "Agile deployment, scalable infrastructure, and the foundationfor continuous innovation.",
  },
  {
    logo:  dev ,
    heading: "Web Development",
    about:
      "Your digital storefront, built for impact and seamless user experiences.",
  },
  {
    logo: QA ,
    heading: "QA & Testing",
    about:
      "Rigorous testing ensures your products launch flawlessly and perform at their peak.",
  },
];
const cardinfo2 = [
  {
    logo:  Mob ,
    heading: "Mobile Development",
    about:
      "Put your business in the palm of their hands with sleek and intuitive mobile apps.",
  },
  {
    logo:  block ,
    heading: "Blockchain Development",
    about:
      " Secure, transparent, and revolutionary solutions powered by blockchain.",
  },
  {
    logo:  UI ,
    heading: "UI/UX Design",
    about:
      "Crafting pixel poetry, our designs aren't just visually stunning – they're a digital embrace.",
  },
];


const Devlop = (props) => {
  return (
    <>
      <section style={{ color: props.back }} className={style.develop}>
        <div className={`container ${style.develop__container}`}>
          <div className={style.heading}>
            <span>What We Do?</span>
            <h3>Our Development Services</h3>
            <div className={style.line}></div>
          </div>
          <div className={style.develop__options}>
            <div className={style.rowss}>
              {cardinfo.map((element, index) => (
                <Devcards
                  key={index}
                  imgs={element.logo}
                  head={element.heading}
                  about={element.about}
                />
              ))}
            </div>
            <div className={style.rowss}>
              {cardinfo2.map((element, index) => (
                <Devcards
                  key={index}
                  imgs={element.logo}
                  head={element.heading}
                  about={element.about}
                />
              ))}

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Devlop;
