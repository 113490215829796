import React from "react";
import style from "../clientCompany/Clientcom.module.css";
import comp1 from "../../../assets/home/company/comp1.png";
import comp2 from "../../../assets/home/company/comp2.png";
import comp3 from "../../../assets/home/company/comp3.png";
import comp4 from "../../../assets/home/company/comp4.png";
import comp5 from "../../../assets/home/company/comp5.png";
import comp6 from "../../../assets/home/company/comp6.png";
import comp7 from "../../../assets/home/company/comp7.png";
import comp8 from "../../../assets/home/company/comp8.png";
import comp9 from "../../../assets/home/company/comp9.png";

const images = [comp1, comp2, comp3, comp4, comp5, comp6, comp7, comp8,comp9];

const ClientCom = () => {
  return (
    <>
      <div className={style.frame}>
        {images.map((element, key) => (
          <div key={key} className={style.pictures}>
            {" "}
            <img src={element} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ClientCom;
