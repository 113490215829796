import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Navbar from "./components/Shared/navbar/Navbars";
import Services from "./Pages/Services/Services";
import About from "./Pages/About/About";
import Career from "./Pages/Career/Career";
import Contact from "./Pages/Contact/Contact";
import Footer from "./components/Shared/footer";
import blogs from "./Pages/Blogs/Blogs"





function App() {
  return (
    <>
      <BrowserRouter>
        <div>
          {/* <Navbar/> */}
          <Routes>
            <Route path="/" exact Component={Home} />
            <Route path="/about" Component={About} />
            <Route path="/services" Component={Services} />
            <Route path="/contact" Component={Contact} /> 
            <Route path="/career" Component={Career} />
            <Route path="/blogs" Component={blogs} />
          </Routes>
          <Footer/>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
