import React from "react";
import style from "../PhotoGallary/galary.module.css";
import img1 from "../../../assets/gallary/long1.png";
import img2 from "../../../assets/gallary/long2.jpg";
import img3 from "../../../assets/gallary/long3.png";
import img4 from "../../../assets/gallary/wide1.png";
import img5 from "../../../assets/gallary/wide2.jpg";
import img8 from "../../../assets/gallary/wider.png";
import img9 from "../../../assets/gallary/wider2.png";

const images = [
  { src: img1, className: style.long},
  { src: img9, className: style.wide},
  { src: img5, className: style.normal},
  { src: img2, className: style.long2},
  { src: img4, className: style.normal2},
  { src: img3, className: style.long3},
  { src: img5, className: style.normal3},
  { src: img4, className: style.normal4},
  { src: img4, className: style.normal5},
  { src: img8, className: style.wide2},
];

const Galary = () => {
  return (
    <>
      <div className={style.main}>
        <div className={style.gallery}>
          {images.map((element,index) => (
            <img key={index} src={element.src} className={element.className} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Galary;
