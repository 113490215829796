import React from "react";
import style from "../Career/career.module.css";
import Scroll from "../../components/Shared/scrolldown/Scroll";
import Aboutb from "../../components/Home/AboutB/Aboutb"
import Galary from "../../components/Shared/PhotoGallary/Galary";
import Pack from "../../components/Career/Pack/Pack"
import Positions from "../../components/Career/Positions/Positions";
import Navbar from "../../components/Shared/navbar/Navbars";
const Career = () => {
  return (
    <>
    <Navbar career = "uline"/>
      <div className={style.header}>
        <h2>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since.
        </h2>
        <p>
          containing Lorem Ipsum passages, and more recently with desktop
          publishing software like Aldus PageMaker including versions of Lorem
          Ipsum. Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type <br /> <br /> been the industry's standard dummy text ever since the 1500s,
          when an unknown printer took a galley of type and scrambled it to make
          a type specimen book. It has survived not only five centuries.
        </p>
        <Aboutb btn = "ABOUT US" link1="/about"/>
      </div>
      {/* <Scroll /> */}

      <Galary/>
      <Pack/>
      <Positions/>
      


    </>
  );
};

export default Career;
