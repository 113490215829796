import React from "react";
import "../boxwhite/boxwhite.css";
import Years from "./years/Years";

const Boxwhite = () => {
  return (
    <>
      <div className="box">
        <div className="innerbox">
          <div className="headings">
            <div className="headin">
              <h1>Our Story Yet!</h1>
              <div className="line"></div>
            </div>
            <div className="para">
              Cubexo was born from our frustration with hindrances like
              disjointed development cycles and elusive talent searches. We
              envisioned a better way – connecting businesses with top-tier
              developers and designers, emphasizing speed, agility, and ROI. Our
              commitment to authenticity led to Cubexo's creation, a genuine
              effort to revolutionize the industry.
            </div>
          </div>
          <div className="animated">
            <div className="num">
              <Years number="5" attri="Years" />
            </div>
            <div className="num">
              <Years number="67" attri="Projects" />
            </div>
            <div className="num">
              <Years number="100" attri="Experts" />
            </div>
            <div className="num">
              <Years number="40" attri="Clients" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Boxwhite;
