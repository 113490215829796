import React from "react";
import style from "../Pack/pack.module.css";
import Invite from "./invite/Invite";

const Pack = () => {
  return (
    <>
      <div className={style.backBox}>
        <div className={style.conten}>
          <div className={style.leftcont}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheetsv Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Architecto facere saepe nisi ab
            debitis cupiditate quasi possimus incidunt sint quam.
          </div>
          <div className={style.rightcont}>
            LLorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets Lorem ipsum dolor sit, amet
            consectetur adipisicing elit. Iure maiores reprehenderit distinctio
            autem nam. Perspiciatis, eos quos. Molestias, nulla doloribus!
          </div>
        </div>

        <h2 className={style.hoe}>Why should you the pack ?</h2>

        <div className={style.twocards}>
          <Invite />
          <Invite />
        </div>
      </div>
    </>
  );
};

export default Pack;
